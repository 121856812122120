@import 'styles/reset';
@import 'styles/quill-editor-override-styles';
@import 'styles/mat-datepicker-override-styles';
@import 'styles/core';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import 'ngx-toastr/toastr';
@import 'styles/toastr.scss';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans 3';
}

sup {
  vertical-align: super;
  font-size: smaller;
  line-height: 1;
}

sub {
  vertical-align: sub;
  font-size: smaller;
  line-height: 1;
}
