$active: #bedde0;
$active-input: #edf5fd;
$black222: #222222;
$black333: #333333;
$blue: blue;
$c2a: #0071e3;
$c2a05: #0071e30d;
$c2a-hover: #2885e3;
$completed: #6bc77f;
$error: #ea1f4c;
$focus: #0047ff;
$grey444: #444444;
$grey666: #666666;
$grey999: #999999;
$greyCCC: #cccccc;
$greyD9: #d9d9d9;
$greyDDDC: #dddcdc;
$greyDDD: #dddddd;
$greyEA: #eaeaea;
$greyEEE: #eeeeee;
$greyF4: #f4f4f4;
$greyF8: #f8f8f8;
$in-progress: #4f98cc;
$planned: #666666;
$primary: #4ad1da;
$red: red;
$secondary: #183642;
$secondary-hover: #275568;
$shadowColor: rgba(0, 0, 0, 0.25);
$success: #23d468;
$to-audit: #ff9900;
$warning: #ff9900;
$white: #ffffff;
