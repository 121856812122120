@use './colors';

.mat-datepicker-content {
  background: colors.$white;
  border: 1px solid colors.$greyDDD;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.mat-calendar {
  height: 376px !important;
}

.mat-calendar-content {
  font-family: 'Source Sans 3';
  padding: 0 8px 32px 8px !important;
  .mat-calendar-table {
    thead {
      .mat-calendar-table-header-divider::after {
        background: transparent;
      }
      tr th {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;

        color: colors.$grey999;
      }
    }

    tbody {
      .mat-calendar-body-label {
        opacity: 0;
        padding: 0 !important;
      }

      td.mat-calendar-body-cell-container {
        button {
          &:hover {
            .mat-calendar-body-cell-content {
              background: colors.$greyEA !important;
            }
          }
          .mat-calendar-body-cell-content {
            width: 36px;
            height: 36px;
            border-radius: 4px;
            background: transparent !important;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: colors.$black333;

            &.mat-calendar-body-today {
              border-color: transparent;
            }

            &.mat-calendar-body-selected {
              background: colors.$active-input !important;
              border-color: colors.$c2a;
              color: colors.$c2a;
              font-weight: 700;
            }
          }
          &.mat-calendar-body-disabled {
            .mat-calendar-body-cell-content {
              background: transparent !important;
              opacity: 0.25;
            }
          }
        }
      }
    }
  }
}
