/* Here we can forward multiple modules such as colors, breakpoints, sizes etc.
   To use it in component scss file, just insert @use 'src/styles/core'; or @use 'src/styles/core' as *;
   at top of the file

   with @use 'src/styles/core' you will get access to variable with core.<module>.<variable>.
   e.g core.colors.$blue.

   with @use 'src/styles/core' as *; you only need to type <variable> e.g $blue.
*/

@forward './colors';
@forward './typography';
@forward './table';

@mixin bordered($color: rgba(255, 255, 255, 0), $width: 0px, $radius: 4px) {
  border: $width solid $color;
  border-radius: $radius;
}

@mixin flexbox($direction, $justifyContent: start, $alignItems: start) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin customScrollbar($color) {
  scrollbar-width: 8px;
  scrollbar-color: $color;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    border-radius: 4px;
  }
}
