quill-editor {
  .ql-editor {
    position: relative;
    padding: 16px 4px 12px 12px;
    font-family: 'Source Sans 3';

    ::selection {
      background: #e73373;
      color: white;
    }
    ::-moz-selection {
      background: #e73373;
      color: white;
    }

    &::before {
      font-size: inherit;
      line-height: inherit;
      font-style: normal !important;
      color: #999999 !important;
    }

    p {
      line-height: 1.56 !important;
    }

    h2 {
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-size: 24px !important;
      line-height: 28px !important;
    }

    ol,
    ul {
      padding-left: 0;
      li {
        line-height: 28px;
      }
    }

    em {
      font-style: italic;
    }

    sub {
      vertical-align: sub;
      font-size: smaller;
    }
  }

  .ql-container.ql-resize {
    overflow: hidden;
    resize: vertical;
  }

  .ql-toolbar .ql-header.ql-picker .ql-picker-label {
    display: flex;
    align-items: center;
    border: none;
    &::before {
      line-height: 36px;
    }
  }
}

quill-view-html {
  .ql-editor {
    padding: 0;

    a {
      color: #0071e3;
    }

    em {
      font-style: italic;
    }

    ol,
    ul {
      padding-left: 0;
    }
  }

  &.standard .ql-editor {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #333333;

    overflow-y: hidden;

    h2 {
      padding: 0;
      margin: 0;
      font-size: 24px !important;
      line-height: 125% !important;
    }

    li::before {
      text-align: center;
    }
  }

  &.html-report .ql-editor {
    line-height: 28px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;

    h2 {
      padding: 0;
      margin: 0;
      font-weight: 400;
      font-size: 24px !important;
      line-height: 28px !important;
    }
  }
}
