.toast-container {
  gap: 20px;

  &.toast-top-right {
    display: flex;
    flex-direction: column;
    top: 68px;
    right: 68px;

    align-items: flex-end;
  }
}
